import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Function to generate a random color
function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

// Function to generate a new circular favicon with a random color
function setRandomFavicon() {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Set canvas size to 32x32 (standard favicon size)
  canvas.width = 32;
  canvas.height = 32;

  // Draw a circle with a random color
  const radius = canvas.width / 2;  // Radius of the circle (half of canvas width)
  const centerX = canvas.width / 2; // X-coordinate of the center
  const centerY = canvas.height / 2; // Y-coordinate of the center

  // Function to update the favicon with a new color
  function updateFavicon() {
    // Remove any existing favicons to prevent duplicates
    const existingFavicon = document.querySelector("link[rel='icon']");
    if (existingFavicon) {
      existingFavicon.remove();
    }

    // Set a new random color
    const randomColor = getRandomColor();

    // Draw the circle with the new random color
    ctx.fillStyle = randomColor;
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, 0, Math.PI * 2); // Draw the circle
    ctx.fill(); // Fill the circle with the color

    // Create a new favicon
    const favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = canvas.toDataURL('image/png');

    // Append the new favicon to the head
    document.head.appendChild(favicon);
  }

  // Call the update function every second (1000ms)
  setInterval(updateFavicon, 1000);

  // Initial favicon set up
  updateFavicon();
}

// Set a random circular favicon that changes every second
window.onload = setRandomFavicon;
