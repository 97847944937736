import "./App.css";
import Contact from "./components/contact";
import FabMenu from "./components/FabMenu";
import Home from "./components/home";
import Education from "./components/education";
import React, { useState, useEffect } from "react";
import Experience from "./components/experience";

function App() {
  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const sections = document.querySelectorAll("section");

    const options = {
      threshold: 0.5, // When 10% of the section is in view
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id); // Set active section when visible
        }
      });
    }, options);

    sections.forEach((section) => {
      observer.observe(section); // Observe each section
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section); // Cleanup the observer on component unmount
      });
    };
  }, []);

  const handleScroll = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        {/* Home */}
        <section id="introduction">
          <Home />
        </section>

        {/* Experience */}
        <section id="experience">
          <Experience />
        </section>

        {/* Education */}
        <section id="education">
          <Education />
        </section>

        {/* Contact
        <section id="contact">
          <Contact />
        </section> */}
      </header>
      <FabMenu onClick={handleScroll} activePage={activeSection} />
    </div>
  );
}

export default App;
